.blog__neon-button {
  --clrNeon: hsl(302 79% 37%);
  --clrBg: hsl(0 0% 10%);
  font-size: 1rem;

  display: inline-block;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  color: var(--clrNeon);
  border: var(--clrNeon) 0.125em solid;
  padding: 0.25em 1em;
  border-radius: .25em;

  text-shadow: 0 0 0.1em currentColor;

  box-shadow:
  0 0 0.5em 0 var(--clrNeon),
  inset 0 0 0.5em 0 var(--clrNeon);

  position: relative;
  z-index: 3;
}

.blog__neon-button:visited {
  color: var(--clrNeon);;
}

.blog__neon-button::before {
  --clrNeon: hsl(302 79% 37%);
  --clrBg: hsl(0 0% 10%);
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clrNeon);
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;

  transform: perspective(1em) rotateX(40deg) scale(1, 0.45);
  filter: blur(1em);
  opacity: 0.7;
  transition: opacity 100ms linear;
}

.blog__neon-button::after {
  --clrNeon: hsl(302 79% 37%);
  --clrBg: hsl(0 0% 10%);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2em 0.5em var(--clrNeon);
  opacity: 0;
  z-index: 1;
  transition: opacity 100ms linear;
}

.blog__neon-button:hover, .blog__neon-button:focus {
  --clrNeon: hsl(302 79% 37%);
  --clrBg: hsl(0 0% 10%);
  background: var(--clrNeon);
  color: var(--clrBg);
  text-shadow: 0 0 0.1em currentColor;
  text-decoration: none;
}

.blog__neon-button:hover::after, .blog__neon-button:focus::after  {
  opacity: 1;
}

.blog__neon-button:hover::before, .blog__neon-button:focus::before  {
  opacity: 1;
}
