.image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
}

.full {
  transition: opacity .4s;
}

.thumb {
  filter: blur(2px);
  transition: visibility 0s .4s;
  transform: scale(.98);
}

.blog__button-container {
  text-align: center;
}

.blog__table-of-contents {
  margin: auto;
}

td:not(:first-child) {
  padding-left: 2rem;
}

.blog__neon-button {
  --clrNeon: #a914a4;
  --clrBg: #1a1a1a;
  max-width: 200px;
  cursor: pointer;
  color: var(--clrNeon);
  border: var(--clrNeon) .125em solid;
  text-shadow: 0 0 .1em;
  box-shadow: 0 0 .5em 0 var(--clrNeon), inset 0 0 .5em 0 var(--clrNeon);
  z-index: 3;
  border-radius: .25em;
  padding: .25em 1em;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.blog__neon-button:visited {
  color: var(--clrNeon);
}

.blog__neon-button:before {
  --clrNeon: #a914a4;
  --clrBg: #1a1a1a;
  pointer-events: none;
  content: "";
  background: var(--clrNeon);
  width: 100%;
  height: 100%;
  filter: blur(1em);
  opacity: .7;
  transition: opacity .1s linear;
  position: absolute;
  top: 100%;
  left: 0;
  transform: perspective(1em)rotateX(40deg)scale(1, .45);
}

.blog__neon-button:after {
  --clrNeon: #a914a4;
  --clrBg: #1a1a1a;
  content: "";
  box-shadow: 0 0 2em .5em var(--clrNeon);
  opacity: 0;
  z-index: 1;
  transition: opacity .1s linear;
  position: absolute;
  inset: 0;
}

.blog__neon-button:hover, .blog__neon-button:focus {
  --clrNeon: #a914a4;
  --clrBg: #1a1a1a;
  background: var(--clrNeon);
  color: var(--clrBg);
  text-shadow: 0 0 .1em;
  text-decoration: none;
}

.blog__neon-button:hover:after, .blog__neon-button:focus:after, .blog__neon-button:hover:before, .blog__neon-button:focus:before {
  opacity: 1;
}

.home__profile-pic {
  float: left;
  margin-right: 14px;
}

.home__monkey-pic {
  float: right;
  margin-left: 14px;
}

@media only screen and (max-width: 500px) {
  .home__profile-pic {
    float: none;
    margin: 0 auto 10px;
  }
}

.journal {
  width: 595px;
  height: 800px;
  background: #d3d3d3;
  margin: auto;
  position: relative;
}

.journal__day {
  --day-size: 75;
  width: calc(var(--day-size) * 1px);
  height: calc(var(--day-size) * 1px);
  background: #fff;
  border: 1pt solid #000;
  justify-content: center;
  align-items: center;
  margin: 5px;
  display: flex;
  position: absolute;
}

.day__day-number {
  font-size: 8px;
}

.s {
  background: #0ff;
}

.day__day-number > p {
  margin: 0;
}

.mh5:after, .mh4:after, .mh3:after, .mh2:after {
  content: "";
  width: 4px;
  background: #0ff;
  position: absolute;
  bottom: 0;
  right: 0;
}

.mh5:after {
  height: calc(var(--day-size) * 1px - 1);
}

.mh4:after {
  height: calc(var(--day-size) * .8px);
}

.mh3:after {
  height: calc(var(--day-size) * .6px);
}

.mh2:after {
  height: calc(var(--day-size) * .4px);
}

.smiley {
  font-size: 30px;
}

svg {
  fill: #fff;
  stroke: #000;
  stroke-width: .2px;
  stroke-linecap: round;
  background: #dedede;
}

.marks {
  stroke-width: .2px;
}

.label {
  stroke: #00f;
  fill: #00f;
  font: 2px serif;
}

.time {
  font: 3px serif;
}



.programming__title {
  text-align: center;
  margin: auto;
}

.project-content {
  text-align: center;
  margin: 20px auto auto;
}

.project-content__ray-tracer {
  margin-left: -50px;
}

.project-content__oversize canvas {
  width: calc(1px * var(--main-content-width) );
}

.project-content__dark-background canvas {
  background: #333;
}

.main-header {
  z-index: 2;
  height: 60px;
  min-width: 250px;
  border-bottom: 1px solid var(--light-grey);
  background: #fff;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px;
  display: flex;
  position: sticky;
  top: 0;
}

.main-header__nav {
  text-transform: uppercase;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
  display: flex;
}

.nav__item {
  color: #000;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.nav__item:hover {
  cursor: pointer;
  text-decoration: none;
}

.nav__item:visited {
  color: #000;
}

.nav__item--selected {
  border-bottom: 2px solid var(--main-accent-color);
  border-radius: 2px;
  transition: all .5s;
}

.nav__label {
  display: inline;
}

.nav__icon {
  height: 28px;
  margin: 3px;
  display: none;
}

@media screen and (max-width: 500px) {
  .main-header {
    height: auto;
  }

  .nav__item {
    margin: .3rem 0;
  }

  .nav__label {
    display: none;
  }

  .nav__icon {
    display: inline;
  }
}

.main-footer {
  height: 60px;
  width: 100%;
  text-align: center;
  background: var(--very-light-grey);
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.main-footer__content {
  margin: 0;
  padding: 15px;
}

.main-footer__content:hover {
  cursor: default;
}

.main-footer__logo {
  height: 18px;
  margin: 1px 5px;
}

:root {
  --main-accent-color: #a914a4;
  --secondary-accent-color: #843982;
  --light-grey: #ededed;
  --very-light-grey: #f5f5f5;
  --main-content-width: 600;
}

@font-face {
  font-family: arkhip;
  src: url("arkhip.950b0080.otf") format("opentype");
}

@font-face {
  font-family: gothic;
  src: url("gothic.8441fee0.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  color: #000;
  margin: 0;
  padding-bottom: 60px;
  font-family: gothic, serif;
}

h1 {
  color: #000;
  letter-spacing: .5px;
  text-transform: uppercase;
  margin: 0;
  font-family: arkhip, sans-serif;
  font-size: 40px;
}

h2 {
  color: #000;
  letter-spacing: .5px;
  text-transform: uppercase;
  margin: 20px 0 8px;
  font-size: 22px;
}

p {
  margin: 10px 0;
}

ul {
  margin: 4px 0;
  padding-left: 0;
  list-style: none;
}

a {
  color: var(--main-accent-color);
  text-decoration: none;
}

a:visited {
  color: var(--secondary-accent-color);
}

a:hover {
  text-decoration: underline;
}

code, code * {
  background-color: var(--light-grey);
  margin: 0;
}

main {
  max-width: 60%;
  min-width: calc(var(--main-content-width) * 1px);
  margin: 30px auto;
}

@media only screen and (max-width: 500px) {
  h1, h2 {
    text-align: center;
  }

  main {
    min-width: 300px;
    margin: 30px auto;
  }
}

/*# sourceMappingURL=index.0b47a8ac.css.map */
