.image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.full {
  transition: opacity 400ms ease 0ms;
}

.thumb {
  filter: blur(2px);
  transform: scale(.98);
  transition: visibility 0ms ease 400ms;
}
