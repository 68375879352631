.main-footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--very-light-grey);;
}

.main-footer__content {
  margin: 0;
  padding: 15px;
}

.main-footer__content:hover {
  cursor: default;
}

.main-footer__logo {
  height: 18px;
  margin: 1px 5px;
}
