svg {
  background: #dedede;
  fill: white;
  stroke: black;
  stroke-width: 0.2;
  stroke-linecap: round;
}

.marks {
  stroke-width: 0.2;
}

.label {
  font: normal 2px serif;
  stroke: blue;
  fill: blue;
}

.time {
  font: normal 3px serif;
}
